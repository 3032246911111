:root {
  --font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --font-size: 16px;
}

ul {
  margin-right: 0;
}

ul li {
  list-style-type: disc;
}

h1 {
  font-size: 2rem;
  letter-spacing: -0.8px;
  margin-bottom: 0rem;
}

h2 {
  border-bottom: 1px solid rgb(236, 64, 122);
  color: rgb(236, 64, 122);

  font-size: 1.15rem;
  margin-bottom: 1rem;
}

h3 {
  font-size: 1.15rem;
  font-weight: 600;
}

hgroup {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  :root {
    --font-size: 14px;
  }

  ul {
    margin-right: 160px;
  }

  h1 {
    font-size: 2.75rem;
  }

  hgroup {
    margin-bottom: 0.5rem;
  }

  section {
    margin-bottom: 2rem;
  }
}
